/* Cau hinh danh sach field */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MyDateEditor from "../components/MyDateEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import { DataGrid, viVN } from "@mui/x-data-grid";
import { showError, showSuccess, handleServerError } from "../lib/common";
import ColumnConfigForm from "./ColumnConfigForm";
import ButtonConfigForm from "./ButtonConfigForm";
const server = require("../lib/server");

/* KHAI BAO MAIN FUNCTION */
export default function SearchFormConfig(props) {
  const [saving, setSaving] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState({ ...props.FormConfig });
  const [dataChanged, setDataChanged] = React.useState(false);
  const [gridColumns, setGridColumns] = React.useState(
    props.FormConfig.GridColumns
  ); //danh sach column cua grid

  const [showColumnConfig, setShowColumnConfig] = React.useState(false); //hien thi form config column
  const [selectedColumn, setSelectedColumn] = React.useState(null); // dang edit column nao
  //danh sach cac nut button
  const [actionButtons, setActionButtons] = React.useState(
    props.FormConfig.ActionButtons
  );
  const [showConfigButton, setShowConfigButton] = React.useState(false);
  const [selectedActionButton, setSelectedActionButton] = React.useState(null); // dang config button nao
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...dataEdit };
    newData[fieldName] = value;
    setDataEdit(newData);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj) {
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
  }
  //ham xu ly viec save du lieu
  const doSave = () => {
    setSaving(true);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DA_Forms_Update",
        ThamSo: {
          FormID: props.FormID,
          GridColumns: gridColumns,
          SearchOnLoad: dataEdit.SearchOnLoad,
          SearchButton: dataEdit.SearchButton,
          SearchFunction: dataEdit.SearchFunction,
          ActionButtons: actionButtons,
          GridRowID: dataEdit.GridRowID,
          DeleteFunction: dataEdit.DeleteFunction,
          DefaultSortField: dataEdit.DefaultSortField,
          SortDesc: dataEdit.SortDesc,
        },
      })
      .then((response) => {
        setSaving(false);
        showSuccess("Cập nhật thành công");
        props.close(false);
      })
      .catch((error) => {
        handleServerError(error);
        setSaving(false);
      });
  };
  return (
    <Dialog maxWidth={"xl"} open={true}>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            Trường thông tin
          </Typography>
          <Stack direction="row" spacing={4}>
            <Button
              variant="contained"
              onClick={() => {
                doSave();
              }}
            >
              Lưu
            </Button>

            <Button
              color="success"
              variant="contained"
              onClick={() => props.close()}
            >
              Thoát
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 500,
            minHeight: 30,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
          }}
        >
          <Grid container spacing={1} columns={12}>
            <Grid item xs={4}>
              <FormLabel>Width-Nút tìm kiếm:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <MyNumberEditor
                fullWidth
                scale={0}
                value={dataEdit.SearchButton.Width}
                onValueChange={(value) =>
                  saveData({
                    SearchButton: {
                      Width: value,
                    },
                  })
                }
              ></MyNumberEditor>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Search procedure:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.SearchFunction}
                onChange={(event) =>
                  saveField("SearchFunction", event.target.value)
                }
              ></OutlinedInput>
            </Grid>

            <Grid item xs={4}>
              <FormLabel>Load dữ liệu khi mở form:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.SearchOnLoad}
                onChange={(event) =>
                  saveField("SearchOnLoad", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <FormLabel>Trường khóa chính:</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <OutlinedInput
                fullWidth
                value={dataEdit.GridRowID}
                onChange={(event) => saveField("GridRowID", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Delete procedure:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <OutlinedInput
                fullWidth
                value={dataEdit.DeleteFunction}
                onChange={(event) =>
                  saveField("DeleteFunction", event.target.value)
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>Default sort:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <OutlinedInput
                fullWidth
                value={dataEdit.DefaultSortField}
                onChange={(event) =>
                  saveField("DefaultSortField", event.target.value)
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="Giảm dần"
                control={
                  <Checkbox
                    checked={dataEdit.SortDesc}
                    onChange={(event) =>
                      saveField("SortDesc", event.target.checked)
                    }
                  />
                }
              ></FormControlLabel>
            </Grid>
          </Grid>
          {/**Danh sach column trong grid */}
          <Grid container columns={12} spacing={1}>
            <Grid item xs={12}>
              <strong>Danh sách column:</strong>
            </Grid>
            {gridColumns.map((column, index) => (
              <>
                <Grid item xs={8}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setShowColumnConfig(true);
                      setSelectedColumn(column);
                    }}
                  >
                    {column.field}
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    onClick={() => {
                      var list = [].concat(gridColumns);
                      list.splice(index, 1);
                      setGridColumns(list);
                      setDataChanged(true);
                    }}
                  >
                    Xóa
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    onClick={() => {
                      var list = [].concat(gridColumns);
                      list.splice(index, 1);
                      if (index == 0) {
                        list.push(column);
                      } else {
                        list.splice(index - 1, 0, column);
                      }
                      setGridColumns(list);
                      setDataChanged(true);
                    }}
                  >
                    Lên
                  </Button>
                </Grid>
              </>
            ))}
            <Grid item xs={12}>
              <Button
                color="success"
                onClick={() => {
                  var list = [].concat(gridColumns);
                  list.push({});
                  setGridColumns(list);
                  setDataChanged(true);
                }}
              >
                Thêm column
              </Button>
            </Grid>
          </Grid>
          {/**Danh sach action button */}
          <Grid container columns={12} spacing={1}>
            <Grid item xs={12}>
              <strong>Danh sách button:</strong>
            </Grid>
            {actionButtons.map((button, index) => (
              <>
                <Grid item xs={8}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setShowConfigButton(true);
                      setSelectedActionButton(button);
                    }}
                  >
                    {button.Text}
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    onClick={() => {
                      var list = [].concat(actionButtons);
                      list.splice(index, 1);
                      setActionButtons(list);
                      setDataChanged(true);
                    }}
                  >
                    Xóa
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="error"
                    onClick={() => {
                      var list = [].concat(actionButtons);
                      list.splice(index, 1);
                      if (index == 0) {
                        list.push(button);
                      } else {
                        list.splice(index - 1, 0, button);
                      }
                      setActionButtons(list);
                      setDataChanged(true);
                    }}
                  >
                    Lên
                  </Button>
                </Grid>
              </>
            ))}
            <Grid item xs={12}>
              <Button
                color="success"
                onClick={() => {
                  var list = [].concat(actionButtons);
                  list.push({});
                  setActionButtons(list);
                  setDataChanged(true);
                }}
              >
                Thêm button
              </Button>
            </Grid>
          </Grid>
        </div>
        {
          /**Hien thi form config column
           */
          showColumnConfig ? (
            <ColumnConfigForm
              ColumnConfig={selectedColumn}
              save={(column) => {
                //tim truong va thay the
                var list = [].concat(gridColumns);
                var index = list.findIndex(
                  (item) => item.field === selectedColumn.field
                );
                list.splice(index, 1, column);
                setGridColumns(list);
                setShowColumnConfig(false);
                setDataChanged(true);
              }}
              close={() => setShowColumnConfig(false)}
            />
          ) : null
        }
        {
          /**Hien thi form config button
           */
          showConfigButton ? (
            <ButtonConfigForm
              ButtonConfig={selectedActionButton}
              save={(column) => {
                //tim truong va thay the
                var list = [].concat(actionButtons);
                var index = list.findIndex(
                  (item) => item.Action === selectedActionButton.Action
                );
                list.splice(index, 1, column);
                setActionButtons(list);
                setShowConfigButton(false);
                setDataChanged(true);
              }}
              close={() => setShowConfigButton(false)}
            />
          ) : null
        }
      </DialogContent>
    </Dialog>
  );
}
