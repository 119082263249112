import React, { useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import Divider from "@mui/material/Divider";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    //   children: `${name.split(' ')[0][0]}`,
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const MenuAccount = ({
  handleMenuItemClick
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Cấu hình">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={()=>handleMenuItemClick("search")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
            Cấu hình tiêu chí tìm kiếm
        </MenuItem>
        <Divider />
        <MenuItem onClick={()=>handleMenuItemClick("searchform")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
            Cấu hình form tìm kiếm
        </MenuItem>
        <Divider />
        <MenuItem onClick={()=>handleMenuItemClick("gencode")}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
            Công cụ code SQL
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default MenuAccount;