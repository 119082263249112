/** Thay doi form hien thi de edit DM_NhomDoiTuong */
function hack_Sua_DM_LoaiGCN(selectedRow, allForms, FormID) {
  //tuy thuoc vao du lieu trong ban ghi selected trong grid ma hien thi form tuong tung
  let isGcn = selectedRow.IsGCN;
  var form = null;
  if (isGcn) {
    form = allForms.find((item) => item.FormID === "DM_LoaiGCN_Edit_GCN");
  }
  else {
    form = allForms.find((item) => item.FormID === "DM_LoaiGCN_Edit_ThietBi");
  }
  if (form) {
    return {
      FormID: FormID,
      FormConfig: form.FormConfig,
    };
  }

  return null;
}
//xu ly gan mac dinh ten GCN theo ten nhom doi tuong trong danh muc
function HS_DoiTuong_Edit_GCN_onChange(newValues, editData) {
  if(newValues["MaNhomDoiTuong_Ten"]){
    newValues.TenDoiTuong = newValues["MaNhomDoiTuong_Ten"];
  }
  return newValues;
}
//xu ly du lieu khi edit thiet bi: selectedItem la item trong combo
function HS_DoiTuong_Edit_ThietBi_onChange(newValues, editData, selectedItem) {
  if(newValues["MaNhomDoiTuong_Ten"]){
    newValues.TenDoiTuong = newValues["MaNhomDoiTuong_Ten"];
    newValues.CoThanhPhan = selectedItem["NhieuThanhPhan"];
  }
  return newValues;
}

/* Chua cac ham JS de can thiep vao dynamic form */
export const HackFunctionList = {
  hack_Sua_DM_LoaiGCN: hack_Sua_DM_LoaiGCN,
  HS_DoiTuong_Edit_GCN_onChange: HS_DoiTuong_Edit_GCN_onChange,
  HS_DoiTuong_Edit_ThietBi_onChange: HS_DoiTuong_Edit_ThietBi_onChange
};
