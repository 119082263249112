import React, { useEffect, useState } from "react";
//Style Import
import { Badge, Collapse, ConfigProvider, Layout, Menu, theme } from "antd";
import "../App.css";

import { Icon, LinearProgress, Typography } from "@mui/material";
import LoadingDialog from "../components/LoadingDialog";
import LoginDialog from "../phanquyen/LoginDialog";
//Danh form lien quna phan quyen
import ChangePassDialog from "../phanquyen/ChangePassDialog";
import NhomQuyenForm from "../phanquyen/NhomQuyenForm";
import PhanMemForm from "../phanquyen/PhanMemForm";
import UsersForm from "../phanquyen/UsersForm";

import MenuUser from "../components/MenuUser";
import SearchForm from "../DynamicForms/SearchForm";
import {
  chuanHoaFormConfig,
  handleServerError,
  showError,
} from "../lib/common";
import { CustomFormByName } from "../Forms/CutstomForms";
import HS_Exports_Form from "../Forms/HS_Exports_Form";
import HS_GiayChungNhan_Form from "../Forms/HS_GiayChungNhan_Form";
import HS_Imports_Form from "../Forms/HS_Imports_Form";
import HS_TauGCN from "../Forms/HS_TauGCN";

const server = require("../lib/server");

const { Header, Sider, Content } = Layout;

const HomePage = (JwtToken) => {
  const [state, setState] = React.useState({});
  const [forceRender, setForceRender] = useState(0);
  const [loading, setLoading] = useState(false);
  const [leftMenus, setLeftMenus] = useState([]);
  const [formConfigs, setFormConfigs] = useState([]);
  const [checkingUser, setCheckingUser] = useState(true);
  const [checkingUserEnd, setCheckingUserEnd] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);
  const [checkUserRetry, setCheckUserRetry] = useState(0);

  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("collapsed") == "true"
  );
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    localStorage.getItem("lastLeftMenu")
      ? JSON.parse(localStorage.getItem("lastLeftMenu"))
      : null
  );
  const [activeForm, setActiveForm] = React.useState(null);
  const [canhBaoQuaHan, setCanhBaoQuaHan] = React.useState([]); // du lieu canh bao qua han
  //su dung cau truc nay nham muc dich reload lai form
  React.useEffect(() => {
    let form = selectedMenuItem
      ? formConfigs.find((item) => item.FormID === selectedMenuItem.FormID)
      : null;
    //kiem tra neu co quyen
    if (
      selectedMenuItem &&
      selectedMenuItem.RightID &&
      global.hasRoles(selectedMenuItem.RightID)
    ) {
      setActiveForm(form);
    } else {
      setActiveForm(null);
    }
  }, [selectedMenuItem]);
  //kiem tra user
  useEffect(() => {
    server
      .query("User/GetUser", {})
      .then((response) => {
        setState({ userName: response.UserName });
        global.User = response;
        setCheckingUser(false);
        setCheckingUserEnd(true);
        loadForms();
      })
      .catch((error) => {
        console.log(error);
        var message = "";
        var log = "";
        try {
          var obj = JSON.parse(error);
          var code = obj.code;
          console.log(obj);
          if (code === 401) {
            setCheckingUser(false);
            setCheckingUserEnd(true);
            setShowLogin(window.myconfig.localLogin);
          } else {
            log = obj.traceLog;
            var log_detail = JSON.parse(log);
            setCheckingUser(false);
            setCheckingUserEnd(true);
            //set message
            showError(error);
          }
        } catch (e) {
          message = error + "";
          setCheckingUser(true);
          setCheckingUserEnd(true);
          //set message
          handleServerError(error);
        }
      });
  }, [checkUserRetry]);
  //lay danh sach menu trai'
  const loadForms = () => {
    setLoading(false);
    server
      .post("Data/DoRequest", {
        Function: "Proc_DA_GetAll",
        ThamSo: {},
      })
      .then((response) => {
        const list = []; // danh muc menu theo phan quyen
        //chuyen truong FormConfig thanh du lieu Json
        response.DA_Forms.map(
          (form) => (form.FormConfig = JSON.parse(form.FormConfig))
        );
        response.DA_LeftMenus.map((menu) => {
          menu.FormProps = JSON.parse(menu.FormProps);
          if (menu.MenuID.indexOf("-SHIP") > 0) {
            //la ten tau
            if (global.hasRoles(menu.RightID)) {
              list.push(menu);
            }
          } else {
            list.push(menu);
          }
        });
        setLeftMenus(list); //danh sach menu trai'

        //chuan hoa cac van de lien quan den cau hinh form
        chuanHoaFormConfig(response.DA_Forms);
        setFormConfigs(response.DA_Forms); //danh sach cau hinh form
        //xac dinh form active
        setSelectedMenuItem(
          localStorage.getItem("lastLeftMenu")
            ? JSON.parse(localStorage.getItem("lastLeftMenu"))
            : list.find((m) => !m.IsGroup)
        );
        setLoading(false);
        //theo doi qua han
        updateCanhBaoQuaHan(list);

        const interval = setInterval(() => {
          updateCanhBaoQuaHan(list);
        }, 60 * 1000);
        //hien thi menu ghi nho
        setSelectedMenuItem({ ...selectedMenuItem });
      })
      .catch((error) => {
        handleServerError(error);
        setLoading(false);
      });
  };
  const updateCanhBaoQuaHan = (oldMenus) => {
    server
      .post("Data/DoRequest", {
        Function: "Proc_HS_GiayChungNhan_QuaHan",
        ThamSo: {
          TaiKhoan: global.User.UserName,
        },
      })
      .then((response) => {
        let ds = response.HS_GiayChungNhan; //danh sach tong so quan han theo ShipID
        //kiem tra xem co su thay doi khong
        var tongSo = 0; //dem tong so qua han
        var changed = false;
        ds.map((item) => {
          var found = canhBaoQuaHan.find((old) => old.ShipID == item.ShipID);
          changed =
            changed || !found || found.SoLuongQuaHan != item.SoLuongQuaHan;
          tongSo += item.SoLuongQuaHan;
        });
        canhBaoQuaHan.find((old) => {
          var found = ds.find((item) => item.ShipID == old.ShipID);
          changed =
            changed || !found || found.SoLuongQuaHan != old.SoLuongQuaHan;
        });
        if (changed) {
          var newMenus = [];
          oldMenus.map((menu) => {
            if (menu.FormProps.ShipID && menu.IsGroup) {
              var found = ds.find(
                (item) => item.ShipID === menu.FormProps.ShipID
              );
              menu.SoLuongQuaHan =
                found && found.SoLuongQuaHan ? found.SoLuongQuaHan : 0;
            }
            else {
              if(menu.MenuID === "QUANLY") {
                menu.SoLuongQuaHan = tongSo;
              }
            }
            newMenus.push(menu);
          });
          setLeftMenus(newMenus);
          setCanhBaoQuaHan(ds);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  // lấy m
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // dùng vs state MenuItem
  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setActiveForm(null);
    //ghi nho' vao storage
    localStorage.setItem("lastLeftMenu", JSON.stringify(menuItem));
  };

  const handleBtnCollapsed = () => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
    localStorage.setItem("collapsed", String(newCollapsed));
  };

  const handleReload = () => {
    window.location.href = window.ssoConfig.signInRedirectURL;
  };

  const handleLogout = () => {
    window.localStorage.setItem(window.JwtTokenTag, "");
    window.sessionStorage.setItem(window.JwtTokenTag, "");
    window.location.reload();
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 13,
          borderRadius: 0,
          colorBorder: "none",
          colorText: "#fff",
        },
        components: {
          Layout: {
            headerBg: "#112d5a",
            headerPadding: "4px",
            siderBg: "#254881",
            triggerBg: "#112d5a",
          },
          Menu: {
            darkItemBg: "#254881",
            darkSubMenuItemBg: "#254881",
            itemHeight: 30,
            itemBorderRadius: 0,
            darkItemSelectedBg: "#112d5a",
          },
          Collapse: {
            contentPadding: 0,
            headerPadding: "4px",
          },
        },
      }}
    >
      <Layout>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={"/logo.png"} alt="Logo" style={{ height: "100%" }} />
          <Typography
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              color: "#fff",
              marginLeft: "4px",
            }}
          >
            QUẢN LÝ HỒ SƠ TÀU
          </Typography>
          {!checkingUser && !showLogin ? (
            <MenuUser
              UserName={state.userName}
              onClick={(action) => {
                if (action === "logout") {
                  handleLogout();
                }
                if (action === "donvi") {
                  let form = formConfigs.find(
                    (item) => item.FormID === "UMS_BRANCHES"
                  );

                  setActiveForm(form);
                }
                if (action === "phanmem") {
                  let form = formConfigs.find(
                    (item) => item.FormID === "UMS_SOFTS"
                  );

                  setActiveForm(form);
                }
                if (action === "nhomquyen") {
                  let form = formConfigs.find(
                    (item) => item.FormID === "UMS_RTGROUPS"
                  );

                  setActiveForm(form);
                }
                if (action === "phanquyen") {
                  let form = formConfigs.find(
                    (item) => item.FormID === "UMS_USERS"
                  );

                  setActiveForm(form);
                }
                if (action === "password") {
                  setShowChangePass(true);
                }
                if (action === "version") {
                }
              }}
            />
          ) : null}
        </Header>
        <Layout hasSider>
          <Sider
            collapsible
            collapsed={collapsed}
            collapsedWidth={40}
            onCollapse={() => handleBtnCollapsed()}
            style={{
              position: "fixed",
              left: 0,
              top: 64,
              bottom: 0,
            }}
          >
            {collapsed ? (
              <Menu
                theme="dark"
                mode="inline"
                inlineIndent={2}
                style={{
                  overflow: "auto",
                  height: "calc(100vh - 48px)",
                }}
                defaultSelectedKeys={[selectedMenuItem.MenuID]}
                items={leftMenus
                  .filter((m) => m.GroupID == selectedMenuItem.GroupID)
                  .map((menu) => {
                    return {
                      ...menu,
                      key: menu.MenuID,
                      icon: <Icon>{menu.IconName}</Icon>,
                      title: menu.MenuText,
                    };
                  })}
                onSelect={(item) =>
                  handleMenuItemClick(
                    leftMenus.find((menu) => menu.MenuID == item.key)
                  )
                }
              />
            ) : (
              leftMenus.length > 0 && (
                <Collapse
                  accordion={true}
                  style={{
                    overflow: "auto",
                    height: "calc(100vh - 48px)",
                  }}
                  defaultActiveKey={
                    selectedMenuItem ? [selectedMenuItem.GroupID] : []
                  }
                  items={leftMenus
                    .filter((menu) => menu.IsGroup)
                    .map((menu) => {
                      return {
                        key: menu.MenuID,
                        label: (
                          <Badge count={menu.SoLuongQuaHan} offset={[20, 2]}>
                            {menu.MenuText}
                          </Badge>
                        ),
                        children: (
                          <Menu
                            theme="dark"
                            mode="inline"
                            inlineIndent={4}
                            defaultSelectedKeys={
                              selectedMenuItem ? [selectedMenuItem.MenuID] : []
                            }
                            style={{
                              paddingLeft: 10,
                            }}
                            items={leftMenus
                              .filter((child) => child.GroupID == menu.MenuID)
                              .map((child) => {
                                return {
                                  ...child,
                                  key: child.MenuID,
                                  label: child.MenuText,
                                  icon: <Icon>{child.IconName}</Icon>,
                                };
                              })}
                            onSelect={(item) =>
                              handleMenuItemClick(
                                leftMenus.find(
                                  (menu) => menu.MenuID == item.key
                                )
                              )
                            }
                          />
                        ),
                      };
                    })}
                />
              )
            )}
          </Sider>
          <Layout style={{ marginLeft: collapsed ? 40 : 200 }}>
            <Content
              style={{
                minHeight: "calc(100vh - 84px)",
                margin: "10px",
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {loading ? <LinearProgress /> : null}
              {selectedMenuItem && activeForm ? (
                <div style={{ height: "100%", paddingTop: 2 }}>
                  {activeForm ? (
                    activeForm.FormID === "HS_GiayChungNhan" ||
                    activeForm.FormID === "HS_ChungChi" ? (
                      <HS_GiayChungNhan_Form
                        key={activeForm.FormID}
                        FormID={activeForm.FormID}
                        FormConfig={activeForm.FormConfig}
                        AllFormConfigs={formConfigs}
                        {...selectedMenuItem.FormProps}
                      />
                    ) : activeForm.FormID === "HS_TauGCN" ? (
                      <HS_TauGCN
                        key={activeForm.FormID}
                        FormID={activeForm.FormID}
                        FormConfig={activeForm.FormConfig}
                        AllFormConfigs={formConfigs}
                        {...selectedMenuItem.FormProps}
                      />
                    ) : activeForm.FormID === "HS_Exports" ? (
                      <HS_Exports_Form
                        key={activeForm.FormID}
                        FormID={activeForm.FormID}
                        FormConfig={activeForm.FormConfig}
                        AllFormConfigs={formConfigs}
                        {...selectedMenuItem.FormProps}
                      />
                    ) : activeForm.FormID === "HS_Imports" ? (
                      <HS_Imports_Form
                        key={activeForm.FormID}
                        FormID={activeForm.FormID}
                        FormConfig={activeForm.FormConfig}
                        AllFormConfigs={formConfigs}
                        {...selectedMenuItem.FormProps}
                      />
                    ) : activeForm.FormID === "UMS_SOFTS" ? (
                      <PhanMemForm
                        key={activeForm.FormID}
                        FormID={activeForm.FormID}
                        FormConfig={activeForm.FormConfig}
                        AllFormConfigs={formConfigs}
                        {...selectedMenuItem.FormProps}
                      />
                    ) : activeForm.FormID === "UMS_RTGROUPS" ? (
                      <NhomQuyenForm
                        key={activeForm.FormID}
                        FormID={activeForm.FormID}
                        FormConfig={activeForm.FormConfig}
                        AllFormConfigs={formConfigs}
                        {...selectedMenuItem.FormProps}
                      />
                    ) : activeForm.FormID === "UMS_USERS" ? (
                      <UsersForm
                        key={activeForm.FormID}
                        FormID={activeForm.FormID}
                        FormConfig={activeForm.FormConfig}
                        AllFormConfigs={formConfigs}
                        {...selectedMenuItem.FormProps}
                      />
                    ) : CustomFormByName(activeForm.FormID) ? (
                      CustomFormByName(activeForm.FormID, {
                        key: activeForm.FormID,
                        FormID: activeForm.FormID,
                        FormConfig: activeForm.FormConfig,
                        AllFormConfigs: formConfigs,
                        CustomFormFunction: CustomFormByName,
                        ...selectedMenuItem.FormProps,
                      })
                    ) : (
                      <SearchForm
                        key={activeForm.FormID}
                        FormID={activeForm.FormID}
                        FormConfig={activeForm.FormConfig}
                        AllFormConfigs={formConfigs}
                        CustomFormFunction={CustomFormByName}
                        {...selectedMenuItem.FormProps}
                      />
                    )
                  ) : null}
                </div>
              ) : (
                <div style={{ color: "red" }}>
                  <b>Bạn chưa có quyền truy cập !</b>
                </div>
              )}
              {/**Hien thi loading */}
              {checkingUser ? (
                <LoadingDialog
                  open={true}
                  showRetry={checkingUserEnd}
                  onRetry={() => setCheckUserRetry(checkUserRetry + 1)}
                />
              ) : null}
              {/**Hien thi form login */}
              {showLogin ? (
                <LoginDialog
                  open={showLogin}
                  close={() => {
                    setShowLogin(false);
                    setCheckUserRetry(checkUserRetry + 1);
                  }}
                />
              ) : null}
              {
                /**Hien thi change pass */
                showChangePass ? (
                  <ChangePassDialog
                    open={true}
                    close={() => setShowChangePass(false)}
                  />
                ) : null
              }
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
export default HomePage;
