/* Cau hinh danh sach field */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  Checkbox,
} from "@mui/material";
import MyDateEditor from "../components/MyDateEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import { DataGrid, viVN } from "@mui/x-data-grid";
import { showError, showSuccess, handleServerError } from "../lib/common";
const server = require("../lib/server");

/* KHAI BAO MAIN FUNCTION */
export default function ButtonConfigForm(props) {
  const [dataEdit, setDataEdit] = React.useState({...props.ButtonConfig});
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...dataEdit };
    newData[fieldName] = value;
    setDataEdit(newData);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj) {
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
  }

  return (
    <Dialog maxWidth={"lg"} open={true}>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            Cấu hình Action Button
          </Typography>
          <Stack direction="row" spacing={4}>
            <Button variant="contained" onClick={() => {
              props.save(dataEdit);
            }}>
              Lưu
            </Button>

            <Button
              color="success"
              variant="contained"
              onClick={() => props.close()}
            >
              Thoát
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 500,
            minHeight: 30,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
          }}
        >
          <Grid container spacing={1} columns={12}>
            <Grid item xs={4}>
              <FormLabel>Nhãn của button:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.Text}
                onChange={(event) => saveField("Text", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Action code:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.Action}
                onChange={(event) => saveField("Action", event.target.value)}
              ></OutlinedInput>
            </Grid>
             
            
             
            <Grid item xs={4}>
              <FormLabel>Chiều rộng:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <MyNumberEditor
                fullWidth
                scale={0}
                value={dataEdit.Width}
                onValueChange={(value) => saveField("Width", value)}
              ></MyNumberEditor>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Mã quyền{"(vd:" + window.SoftCode + ".R111)"}:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.RightID}
                onChange={(event) => saveField("RightID", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Mã form:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.FormID}
                onChange={(event) => saveField("FormID", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Hack function:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.HackFunction}
                onChange={(event) => saveField("HackFunction", event.target.value)}
              ></OutlinedInput>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}
