/**Nhap danh muc DinhBien */
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  AppBar,
  Stack,
  Typography,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Select,
  MenuItem,
  Menu,
  Link,
  ListItemText,
  IconButton,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectFilter from "react-select";

import SimpleDialog from "../components/SimpleDialog";
import {
  Block,
  Help,
  Label,
  StayPrimaryLandscapeOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import {
  filterStyles,
  showError,
  handleServerError,
} from "../lib/common";
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validate(data) {
  var ret = validate_Empty(data, ["UserName", "Password"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

export default class LoginDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      danhmuc_coquan: [],
      after: {
        UserName: localStorage.getItem("login_name"),
        Password: "",
        SaveSession: true,
      },
      showConfirmExit: false, //co hien thi xac nhan thoat khong
      showErrorMessage: false,
      showErrorLog: false,
    };
  }
  componentDidMount() {
    this.loadDanhMuc();
  }
  //load thong tin trong ban ghi de edit
  loadDanhMuc() {}
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return {};
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    var check = validate(this.state.after);
    if (!check.error) {
      this.setState({ validateStatus: check, saving: true, errorLog: "" });
      server
        .postNoneBearer("User/Login", this.state.after)
        .then((response) => {
          global.User = response;
          server.saveToken(response.JWT, this.state.after.SaveSession);
          document.cookie = "refresh_token_client=" + response.JWT;
          this.props.close(true);
          if(this.state.after.SaveSession) {
            localStorage.setItem("login_name", this.state.after.UserName);
          }
          else {
            localStorage.setItem("login_name", "");
          }
        })
        .catch((error) => {
          this.setState({ saving: false });
          handleServerError(error);
        });
    } else {
      this.setState({ validateStatus: check });
      toast.error("Vui lòng kiểm tra các trường đánh dấu đỏ!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
    let tau = this.state.tauData;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xs"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div
            style={{
              minWidth: 200,
              minHeight: 30,
              padding: 20,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={3} columns={24}>
              <Grid item xs={6} style={{ paddingTop: 0 }}>
                <img style={{ height: 40 }} src="../logo.png"></img>
              </Grid>
              <Grid item xs={16} style={{ paddingTop: 0, paddingLeft: 0 }}>
                <FormLabel
                  style={{
                    fontSize: 14,
                    fontWeight: 800,
                    display: Block,
                    color: "#007aff",
                  }}
                >
                  MARITIME SOLUTION
                </FormLabel>
                <br />
                <FormLabel
                  style={{
                    fontSize: 10,
                    fontWeight: 800,
                    display: Block,
                    color: "#e74c3c",
                  }}
                >
                  MARITIME SOLUTION SOFTWARES
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    window.open("/help.pdf", "_blank");
                  }}
                >
                  <Help style={{ color: "primary" }} />
                </IconButton>
              </Grid>
              <Grid item xs={24}>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  color="info"
                  placeholder="Tài khoản"
                  fullWidth
                  autoFocus = {myLib.isEmpty(this.state.after.UserName)}
                  value={this.state.after.UserName}
                  onChange={(event) =>
                    this.saveField("UserName", event.target.value)
                  }
                  onKeyPress={(event) => {
                    if (event.code === "Enter") {
                      this.doSave();
                    }
                  }}
                  error={this.state.validateStatus.UserName !== undefined}
                />
              </Grid>
              <Grid item xs={24}>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  color="info"
                  placeholder="Mật khẩu"
                  fullWidth
                  autoFocus = {!myLib.isEmpty(this.state.after.UserName)}
                  type="password"
                  value={this.state.after.Password}
                  onChange={(event) => {
                    this.saveField("Password", event.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.code === "Enter") {
                      this.doSave();
                    }
                  }}
                  error={this.state.validateStatus.Password !== undefined}
                />
              </Grid>
              <Grid item xs={24}>
                <FormControlLabel
                  label="Duy trì phiên đăng nhập"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.after.SaveSession}
                      onChange={(event) =>
                        this.saveField("SaveSession", event.target.checked)
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={24}>
                <center>
                  {this.state.saving ? (
                    <CircularProgress color="inherit" size={28} />
                  ) : (
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => {
                        this.doSave();
                      }}
                    >
                      Đăng nhập
                    </Button>
                  )}
                </center>
              </Grid>
              <Grid item xs={24}>
                <center>
                  <p>-=@2023=-</p>
                </center>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
        <SimpleDialog
          confirm
          message={this.state.errorMessage + "\n" + "(Bấm OK để xem chi tiết)"}
          open={this.state.showErrorMessage}
          close={(ok) => {
            if (ok) {
              this.setState({ showErrorMessage: false, showErrorLog: true });
            } else {
              this.setState({ showErrorMessage: false });
            }
          }}
        />
        <SimpleDialog
          message={this.state.errorLog}
          open={this.state.showErrorLog}
          close={(ok) => {
            this.setState({ showErrorLog: false });
          }}
        />
      </Dialog>
    );
  }
}
