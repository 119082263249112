/* Cau hinh danh sach field */
import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Grid,
  Paper,
  Icon,
  ListItemButton,
  ListItemIcon,
  Box,
  List,
  Typography,
  Stack,
  Button,
  CircularProgress,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  Checkbox,
} from "@mui/material";
import MyDateEditor from "../components/MyDateEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import { DataGrid, viVN } from "@mui/x-data-grid";
import { showError, showSuccess, handleServerError } from "../lib/common";
const server = require("../lib/server");

/* KHAI BAO MAIN FUNCTION */
export default function FieldConfigForm(props) {
  var config = props.FieldConfig;
  if(config.RadioGroup) {
    if(config.RadioLabels.map !== undefined) { //la arrays
      config.RadioLabels = JSON.stringify(config.RadioLabels);
    }
  }
  const [dataEdit, setDataEdit] = React.useState({ ...config });
  //khai bao cac function su dung trong component
  function saveField(fieldName, value) {
    var newData = { ...dataEdit };
    newData[fieldName] = value;
    setDataEdit(newData);
  }
  //ghi nhan tham so filter theo kieu data
  function saveData(obj) {
    var newData = { ...dataEdit, ...obj };
    setDataEdit(newData);
  }

  return (
    <Dialog maxWidth={"xl"} open={true}>
      <DialogTitle
        style={{
          cursor: "move",
          backgroundColor: "#75829B",
          color: "white",
          padding: " 5px 10px",
        }}
        id="draggable-dialog-title"
      >
        <Stack direction="row">
          <Typography
            sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
            variant="h6"
            component="div"
          >
            Trường thông tin
          </Typography>
          <Stack direction="row" spacing={4}>
            <Button
              variant="contained"
              onClick={() => {
                props.save(dataEdit);
              }}
            >
              Lưu
            </Button>

            <Button
              color="success"
              variant="contained"
              onClick={() => props.close()}
            >
              Thoát
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            minWidth: 500,
            minHeight: 30,
            padding: 5,
            fontSize: 10,
            wordWrap: "break-word",
          }}
        >
          <Grid container spacing={1} columns={12}>
            <Grid item xs={4}>
              <FormLabel>Tên trường:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.FieldName}
                onChange={(event) => saveField("FieldName", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Nhãn:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.Label}
                onChange={(event) => saveField("Label", event.target.value)}
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Kiểu dữ liệu:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Select
                fullWidth
                value={dataEdit.DataType}
                onChange={(event) => saveField("DataType", event.target.value)}
              >
                <MenuItem value={"String"}>String</MenuItem>
                <MenuItem value={"Date"}>Date</MenuItem>
                <MenuItem value={"DateTime"}>DateTime</MenuItem>
                <MenuItem value={"Int"}>Int</MenuItem>
                <MenuItem value={"Decimal"}>Decimal</MenuItem>
                <MenuItem value={"Boolean"}>Boolean</MenuItem>
                <MenuItem value={"File"}>File</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Giá trị mặc định:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.DefaultValue}
                onChange={(event) =>
                  saveField(
                    "DefaultValue",
                      event.target.value
                  )
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Tự động chữ hoa:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.UpperCasing}
                onChange={(event) =>
                  saveField("UpperCasing", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Read-only:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.ReadOnly}
                onChange={(event) =>
                  saveField("ReadOnly", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Radio-group:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.RadioGroup}
                onChange={(event) =>
                  saveField("RadioGroup", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Lock Edit:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.LockEdit}
                onChange={(event) =>
                  saveField("LockEdit", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Radio-labels:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.RadioLabels}
                onChange={(event) =>
                  saveField(
                    "RadioLabels",
                      event.target.value
                  )
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Ẩn đi:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.Hidden}
                onChange={(event) =>
                  saveField("Hidden", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Multiline:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.MultiLine}
                onChange={(event) =>
                  saveField("MultiLine", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Danh mục:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.SourceName}
                onChange={(event) =>
                  saveField(
                    "SourceName",
                      event.target.value
                  )
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Danh mục - value:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.SourceValue}
                onChange={(event) =>
                  saveField(
                    "SourceValue",
                      event.target.value
                  )
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Danh mục - label:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.SourceLabel}
                onChange={(event) =>
                  saveField(
                    "SourceLabel",
                      event.target.value
                  )
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Danh mục - filter:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <OutlinedInput
                fullWidth
                value={dataEdit.SourceFilter}
                onChange={(event) =>
                  saveField(
                    "SourceFilter",
                      event.target.value
                  )
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Danh mục - filter value:</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                fullWidth
                value={dataEdit.SourceFilterBy}
                onChange={(event) =>
                  saveField(
                    "SourceFilterBy",
                      event.target.value
                  )
                }
              ></OutlinedInput>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>Auto filter:</FormLabel>
            </Grid>
            <Grid item xs={2}>
              <Checkbox
                checked={dataEdit.SourceFilterAuto}
                onChange={(event) =>
                  saveField("SourceFilterAuto", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Auto focus:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={dataEdit.AutoFocus}
                onChange={(event) =>
                  saveField("AutoFocus", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Auto reload:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Checkbox
                checked={dataEdit.AutoReload}
                onChange={(event) =>
                  saveField("AutoReload", event.target.checked)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Chiều rộng nhãn:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <MyNumberEditor
                fullWidth
                scale={0}
                value={dataEdit.LabelWidth}
                onValueChange={(value) => saveField("LabelWidth", value)}
              ></MyNumberEditor>
            </Grid>
            <Grid item xs={4}>
              <FormLabel>Chiều rộng ô nhập:</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <MyNumberEditor
                fullWidth
                scale={0}
                value={dataEdit.InputWidth}
                onValueChange={(value) => saveField("InputWidth", value)}
              ></MyNumberEditor>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}
